* {
  overscroll-behavior: none;
}

.loading__skeleton {
  margin-top: 16px;
  margin-inline: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading__skeleton__logo {
  width: 120px;
  height: 50px;

  margin-bottom: 16px;

  border-radius: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}
.loading__skeleton__heading {
  border-radius: 10px;
  width: 360px;
  height: 90px;

  margin-bottom: 16px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}
.loading__skeleton__text {
  border-radius: 10px;
  width: 360px;
  height: 50px;

  margin-bottom: 16px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}
.loading__skeleton__input {
  border-radius: 10px;
  width: 360px;
  height: 60px;

  margin-bottom: 16px;
  border-radius: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}
.loading__skeleton__button {
  width: 360px;
  height: 60px;

  margin-bottom: 16px;
  border-radius: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}
.loading__skeleton__image {
  border-radius: 10px;
  width: 360px;
  height: 600px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite;
}
@keyframes loadingAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.landingpage {
  margin-top: 0px;
  padding-inline: 16px;
  overflow-x: hidden;
  background-image: url("bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.landingpage__container1 {
}

.hook {
  font-size: 38px;
  color: white;
  font-weight: bold;
  text-align: left;
  margin-top: -20px;
}

.text__colour {
  color: #004cff;
}

.value__proposition {
  letter-spacing: 0.5px;
  margin-top: -28px;
  text-align: left;
  font-size: 22px;
  font-weight: 500;
  color: white;
  margin-bottom: 250px;
}

.form__label {
  color: rgb(255, 255, 255);
}

.form input {
  background-color: #ffffff;
  color: #484848;
  width: 300px;
  font-size: 20px;
  font-weight: 500;
  height: 60px;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-left: 20px; /* Add padding to the left side only */
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cta button {
  width: 100%;
  padding: 12px;
  height: 60px;
  background-color: #004cff;
  margin-top: 16px;
  border: none;
  border-radius: 105px;
  font-size: 20px;
  color: rgb(251, 251, 251);
  font-weight: bold;
  cursor: pointer;
  scale: 1;
  margin-bottom: 24px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation: scaleAnimation 1s infinite alternate; /* Apply the animation */
}

/* Define the keyframes for the scaling animation */
@keyframes scaleAnimation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.03);
  }
}

.connected__dot {
margin-bottom: 20px;
 justify-content: center;
  display: flex;
  flex-direction: row;
  height: 40px;
}
.connected__dot__right {
  
  display: flex;

  margin-top: 12px;
}
.connected__dot__right > p {
  text-align: center;

}

.connected__dot__left {

  display: flex;
  margin-right: 4px;

}
.connected__dot__round {
  margin-left: auto;
  background-color: #34f000;
  height: 6px;
  width: 6px;
  border-radius: 100px;
  margin-top: 14px;
  margin-right: 2px;

}


.counter {
  color: rgb(177, 177, 177);
  font-size: 18px;
  margin-top: -24px;
  text-align: center;

}

.credits {
  text-decoration: none;
  color: #bebebe;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
}

.toaster {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback__user {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  margin-inline: 24px;
  margin-top: -300px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.big__title {
  text-align: center;
  padding-inline: 20px;
  font-size: 26px;
  color: white;
  font-weight: bold;

  margin-bottom: 48px;
}
